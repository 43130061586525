

.App-logo {
  height: 30px;
  float: left;
  animation: App-logo-spin infinite 600s linear;
  margin-right: 20px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
